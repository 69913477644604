import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import { teamMembers } from "../../../data/dummyData";

const Team = () => {
const formatName = (name, id) => {
  if (id === 1) {
    const [mainName, credentials] = name.split("ANIVS");
    return (
      <>
        {mainName}
        <span  className="text-xs">{" "} ANIVS, RSV</span>
      </>
    );
  } else if (id === 2) {
    const [mainName, credentials] = name.split("ACA");
    return (
      <>
        {mainName}
        <span className="text-xs"> ACA</span>
      </>
    );
  } 
  return name;
};

  return (
    <div className="pt-10 pb-16 max-w-[1320px] mx-auto">
      <div className="text-center">
        <h1 className="mx-auto sub-heading">our team</h1>
        {/* <h1 className="heading">meet with our experienced team</h1> */}
      </div>
      <div>
        <div className="mt-8 flex justify-center gap-x-16 gap-y-36 max-md:gap-y-5 flex-wrap max-md:flex-col">
          {teamMembers.map(({ id, name, role, image }) => (
            <div
              className="h-[400px] w-[25%] max-md:w-[90%] mx-auto max-w-[350px] mb-16 lg:mb-0"
              key={id}
            >
              <img
                src={image}
                alt={name}
                className="object-cover w-full h-full rounded-lg object-top"
              />
              <div className="relative z-10 w-4/5 p-2 mx-auto -mt-10 text-center bg-white rounded-lg shadow-md dark:bg-dark-light">
                <h1 className="text-lg font-semibold">
                  {formatName(name, id)}
                </h1>
                <p>{role}</p>
                {/* <div className="mt-3 flex-center-center gap-x-3">
                  <Link className="hover:text-[rgb(235,30,36)] transition-a">
                    <FaFacebook />
                  </Link>
                  <Link className="hover:text-[rgb(235,30,36)] transition-a">
                    <FaInstagram />
                  </Link>
                  <Link className="hover:text-[rgb(235,30,36)] transition-a">
                    <FaTwitter />
                  </Link>
                  <Link className="hover:text-[rgb(235,30,36)] transition-a">
                    <FaLinkedin />
                  </Link>
                </div> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
