import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const Form = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm(
        "service_gas5r3o",
        "contact_form",
        form.current,
        "bg29-vsM3zkC19pIR"
      )
      .then(
        (result) => {
          console.log("Email sent successfully");
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            message: "",
          });
        },
        (error) => {
          console.error("Failed to send email:", error);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="pt-10 pb-8">
      <form ref={form} onSubmit={sendEmail}>
        <div className="flex-col gap-4 flex-align-center sm:flex-row">
          <div className="flex-1 w-full">
            <p>First Name</p>
            <input
              type="text"
              name="firstName"
              className="w-full input rounded"
              placeholder="First Name.."
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="flex-1 w-full">
            <p>Last Name</p>
            <input
              type="text"
              name="lastName"
              className="w-full input rounded"
              placeholder="Last Name.."
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="flex-col gap-4 flex-align-center sm:flex-row mt-4">
          <div className="flex-1 w-full">
            <p>Email Address</p>
            <input
              type="email"
              name="email"
              className="w-full input rounded"
              placeholder="Email Address.."
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="flex-1 w-full">
            <p>Phone Number</p>
            <input
              type="tel"
              name="phone"
              className="w-full input rounded"
              placeholder="Your Phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mt-4">
          <p>Message</p>
          <textarea
            rows={4}
            name="message"
            className="w-full input rounded"
            placeholder="Message.."
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className="mt-4 flex-center-center">
          <button className="btn btn-primary min-w-[250px]" disabled={loading}>
            {loading ? "Sending..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form;
