import { useState } from "react";
import ESV from "../../../Assets/ESV.jpeg";

const AboutUs = () => {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <div className="pt-16 pb-20 max-w-[1300px] mx-auto">
        <div className="flex gap-5 max-md:flex-col max-md:justify-center max-md:items-center">
          <div className="bg-white dark:bg-[rgb(44,48,147)]/50 shadow-2xl w-[70%] max-md:w-[95%] max-md:max-w-[500px] max-w-[800px] mx-auto min-h-auto border-[1px] rounded px-5 py-3">
            <div className="mb-10">
              <h1 className="heading">About us</h1>
              <p className="my-3 font-semibold max-w-[700px]">
                Based in Nigeria, Idi Mfon Consults is a premier Estate
                Surveying and Valuation firm committed to delivering excellence
                and precision in every aspect of real estate services. Our
                seasoned team combines local expertise with international
                standards, providing clients with insightful and tailored
                solutions that maximize property value and minimize risk.
              </p>
            </div>

            <div className="mb-10">
              <h2 className="heading">Core Services</h2>
              <div>
                <div className="my-3">
                  <h3 className="mb-2">
                    <strong>1. Valuation of Assets</strong>
                  </h3>
                  <p>
                    With meticulous analysis, we determine the true market value
                    of diverse assets, from residential and commercial
                    properties to land, machinery, and more. Our valuation
                    services empower clients to make informed decisions with
                    clarity and assurance.
                  </p>
                </div>

                <div className="my-3">
                  <h3 className="mb-2">
                    <strong>2. Property Management</strong>
                  </h3>
                  <p>
                    We provide comprehensive property management services,
                    ensuring optimal functionality and profitability. Our
                    proactive approach covers tenant relations, maintenance,
                    rent collection, and more, enabling property owners to
                    maximize returns with minimal hassle.
                  </p>
                </div>

                {showMore && (
                  <>
                    <div className="my-3">
                      <h3 className="mb-2">
                        <strong>3. Real Estate Investment Advisory</strong>
                      </h3>
                      <p>
                        Our expert advisory team identifies high-potential real
                        estate investment opportunities, helping clients build
                        portfolios that meet their financial objectives. From
                        risk analysis to market insights, we guide investors
                        every step of the way.
                      </p>
                    </div>

                    <div className="my-3">
                      <h3 className="mb-2">
                        <strong>
                          4. Market Research and Feasibility Studies
                        </strong>
                      </h3>
                      <p>
                        Accurate research is the foundation of any successful
                        real estate project. Through thorough market analysis
                        and feasibility studies, we equip clients with valuable
                        data, helping them to make decisions that align with
                        both current trends and future projections.
                      </p>
                    </div>

                    <div className="my-3">
                      <h3 className="mb-2">
                        <strong>5. Real Estate Consultancy</strong>
                      </h3>
                      <p>
                        At Idi Mfon Consults, we offer bespoke consultancy
                        services, addressing clients’ unique needs and guiding
                        them through complex real estate challenges. Our
                        consultations are grounded in a deep understanding of
                        the Nigerian market and global best practices.
                      </p>
                    </div>

                    <div className="my-3">
                      <h3 className="mb-2">
                        <strong>6. Estate Agency</strong>
                      </h3>
                      <p>
                        Our estate agency services are designed to streamline
                        the buying, selling, and leasing processes for all types
                        of real estate. With a vast network and a focus on
                        transparency, we match clients with ideal property
                        solutions efficiently and professionally.
                      </p>
                    </div>

                    <div className="my-3">
                      <h3 className="mb-2">
                        <strong>7. Facility Management</strong>
                      </h3>
                      <p>
                        We provide end-to-end facility management services,
                        ensuring that properties remain safe, functional, and
                        valuable. From maintenance to operational efficiency,
                        our team guarantees that every facility operates
                        smoothly and meets clients’ standards.
                      </p>
                    </div>

                    <div className="my-3">
                      <p>
                        <strong>
                          At Idi Mfon Consults, we are not just a real estate
                          firm – we are your partner in building a prosperous
                          future. Whether you’re an individual, a corporate
                          entity, or an investor, our services are designed to
                          provide peace of mind and enhance your investment
                          potential. Connect with us and take the first step
                          towards informed, profitable real estate ventures.
                        </strong>
                      </p>
                    </div>
                  </>
                )}

                <button
                  onClick={() => setShowMore(!showMore)}
                  className="text-blue-500 font-semibold mt-3"
                >
                  {showMore ? "Show Less" : "Read More"}
                </button>
              </div>
            </div>
          </div>

          <div className="w-[30%] max-md:w-[95%] max-md:max-w-[500px] max-w-[800px] relative">
            <img
              src={ESV}
              alt="Principal consultant image"
              className="w-full"
            />
            <div className="bg-[rgba(0,0,0,0.8)] text-white text-center">
              <h2>
                ESV Idongesit Mfon <span className="text-xs"> ANIVS, RSV</span>{" "}
                – Principal Consultant{" "}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
