import ESV from "../../Assets/ESV.jpeg";

const Stats = () => {
  return (
    <>
      <div className="pt-16 pb-20 max-w-[1300px] mx-auto">
        <div className="flex gap-5 max-md:flex-col max-md:justify-center max-md:items-center">
          <div className="bg-white dark:bg-[rgb(44,48,147)]/50 shadow-2xl w-[70%] max-md:w-[95%] max-md:max-w-[500px] max-w-[800px] mx-auto min-h-auto border-[1px] rounded px-5 py-10 pb-3">
            <div className="mb-10 text-center">
              <h1 className="heading underline">Mission</h1>
              <p className="my-3 font-semibold max-w-[700px] mx-auto">
                At Idi Mfon Consults, our mission is to empower our clients with
                robust, data-driven real estate solutions that inspire
                confidence and create sustainable value. We are dedicated to
                fostering a culture of integrity, professionalism, and
                innovation in every project we undertake, ensuring that every
                client receives unparalleled service and insight.
              </p>
            </div>

            <div className="mb-10 text-center bg-[rgb(246,243,243)] py-3">
              <h1 className="heading underline">Vision</h1>
              <p className="my-3 font-semibold max-w-[700px] mx-auto">
                Our vision is to become the most trusted estate surveying and
                valuation firm in Nigeria, recognized for our commitment to
                client success and our ability to deliver results that stand the
                test of time. We strive to set new benchmarks in the real estate
                industry through our focus on quality, client satisfaction, and
                ethical practices.
              </p>
            </div>
          </div>

          <div className="w-[30%] max-md:w-[95%] max-md:max-w-[500px] max-w-[800px] relative">
            <img
              src={ESV}
              alt="Principal consultant image"
              className="w-full"
            />
            <div className="bg-[rgba(0,0,0,0.8)] text-white text-center">
              <h2>
                ESV Idongesit Mfon <span className="text-xs"> ANIVS, RSV</span>{" "}
                – Principal Consultant{" "}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stats;
